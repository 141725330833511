/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import FileService from '../../services/file';
import StorageService from '../../services/storage';

import { Context } from '../../AppContext';
export default function Addressbook(props) {
    const context = useContext(Context);
    const [rasci, setRasci] = useState();

    //props.dirInfo
    useEffect(() => {
        if (props.id) {
            FileService.getContent(props.file.DPath, StorageService.getStorageFileName(props.file), true)
                .then(content => {
                    let obj = (typeof content === 'string') ? JSON.parse(content) : content;

                    setRasci(obj);
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [props.id])
 
    if (!rasci) return null;
/*

   <div style={{ cursor: 'pointer', margin: '8px' }} onClick={() => props.history.push('/rasci/tasks/' + props.id)}>
                        {rasci.iconTasks? (<img src={rasci.iconTasks} style={{ width: '150px', height: 'auto' }} />) : (<> <b>Taken</b><br /><br /></>)}
                    </div>
                    */
    return (
        <>
                <div style={{ display: 'flex' }}>
                    <div style={{ cursor: 'pointer', margin: '8px' }} onClick={() => props.history.push('/rasci/roles/' + props.id)}>
                        {rasci.iconWorkRoles ? (<img src={rasci.iconWorkRoles} style={{ width: '150px', height: 'auto' }} />) : (<> <b>Taakhouders</b><br /><br />Beschrijving van de taken</>)}
                    </div>
                    <div style={{ cursor: 'pointer', margin: '8px' }} onClick={() => props.history.push('/rasci/processes/' + props.id)}>
                        {rasci.iconWorkProcess ? (<img src={rasci.iconWorkProcess} style={{ width: '150px', height: 'auto' }} />) : (<> <b>Werkprocessen</b><br /><br />Beschrijving van de werkprocessen</>)}
                    </div>
                    <div style={{ cursor: 'pointer', margin: '8px' }} onClick={() => props.history.push('/rasci/table/' + props.id)}>
                        {rasci.iconWorkTable? (<img src={rasci.iconWorkTable} style={{ width: '150px', height: 'auto' }} />) : (<> <b>RASCI</b><br /><br />tabel</>)}
                    </div>
                 
                </div>
        </>
    )
}
