import { Tooltip } from "../components/ui/tooltip";

const ServiceRasci = {
    getColorRasciItem : (rasci) => {
        const templatePCDA = { color: 'white', borderRadius: '2px', margin: '4px', paddingLeft: '6px', paddingRight: '6px',  fontWeight: 'bold',textAlign:'center',width:'13px' ,display:'inline-table'};
    
        if (rasci === 'R') return <Tooltip tooltip="R - Responsible"> <div style={{ ...templatePCDA, backgroundColor: '#c00000' }}>R</div></Tooltip>
        if (rasci === 'A') return <Tooltip tooltip="A - Accountable"> <div style={{ ...templatePCDA, backgroundColor: '#0070c0' }}>A</div></Tooltip>
        if (rasci === 'S') return <Tooltip tooltip="S - Support"> <div style={{ ...templatePCDA, backgroundColor: '#4da72e' }}>S</div></Tooltip>
        if (rasci === 'C') return <Tooltip tooltip="C - Consulted"> <div style={{ ...templatePCDA, backgroundColor: '#ffbf00' }}>C</div></Tooltip>
        if (rasci === 'I') return <Tooltip tooltip="I - Informed"> <div style={{ ...templatePCDA, backgroundColor: 'black' }}>I</div> </Tooltip>
    }
} 
export default ServiceRasci;