import React,{useEffect, useState} from 'react';
import { Paper } from '../../ui/paper';
import TabsProcessesShort from './tabs-processes-short';
import ServiceRasci from '../../../services/rasci';
const ViewTasksFunctions = ({ task, rasci ,onSelectedFunction}) => {
  
    let arrRasci = ["R - Responsible", "A - Accountable", "S - Support", "C - Consult", "I - Informed"];
 

  
  
    return (
        <div >
           <table style={{width:'100%'}}>
            <tbody>
            {arrRasci.map((rasciItem, index) => {
                
                return  rasci.linkedActivities.filter((la) => la.taskID === task._id && la.rasci === rasciItem[0]).map((la, index) => {
                        
                        let foundFunction = rasci.functions.find((p) => p._id === la.functionID);
                       
                        if (!foundFunction) return null;
                        return (
                            <tr key={la._id} style={{paddingTop:'6px',cursor:'pointer'}} onClick={()=>{onSelectedFunction && onSelectedFunction(foundFunction._id)}}> 
                              <td style={{width:'40px'}}>{ServiceRasci.getColorRasciItem(rasciItem[0])}</td>
                            <td  style={{ width: 'auto', padding: '8px', margin: '8px', backgroundColor: '#f7f7f7', border: '1px solid #f7f7f7', cursor: 'pointer' }}>{foundFunction.number} {foundFunction.name}</td></tr>
                        )
                    })
               
            })}
            </tbody>
           </table>
            
        </div>
    );
};

export default ViewTasksFunctions;