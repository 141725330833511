import React, { useEffect, useState } from 'react';
import { SearchBar } from '../../ui/searchBar';
import { Typography } from '../../ui/typography';
import { Tabs } from '../../ui/tabs';
import { Paper } from '../../ui/paper';
import ServiceGeneral from '../../../services/general';
import ViewProcessGeneral from './view-process-general';
import ViewTasksProcesses from './view-process-tasks';
import TabsPDCA from './tabs-pdca-short';
import Menu from './menu';
import ViewTaskGeneral from './view-tasks-general';
const ViewProcess = ({ rasci, selected, selectedTaskID, history, fileID }) => {
    const [selectedProcess, setSelectedProcess] = useState();
    const [selectedView, setSelectedView] = useState(0);
    const [filteredProcesses, setFilteredProcesses] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedPDCA, setselectedPDCA] = useState();
    const [selectedTask, setSelectedTask] = useState();

    useEffect(() => {
        let tmpProcesses = Object.assign([], rasci.processes);
        tmpProcesses = tmpProcesses.filter((process) => { return process.level == 2 });
        //  if (selectedPDCA) {
        ////     tmpProcesses = tmpProcesses.filter((process) => process.pdca === selectedPDCA);
        //  }
        if (searchText) {
            tmpProcesses = tmpProcesses.filter((process) => JSON.stringify(process).toLowerCase().includes(searchText.toLowerCase()));
        }
        setFilteredProcesses(ServiceGeneral.sortJSON(tmpProcesses, 'number', '123'));

        if (selected && rasci.processes && !selectedProcess) {
            let pro = rasci.processes.find((p) => p._id === selected);
            if (pro) setSelectedProcess(pro);

            if (selectedTaskID && rasci.tasks) {
                let foundTask = rasci.tasks.find((t) => { return t._id == selectedTaskID });
                setSelectedTask(foundTask);
                setSelectedView(2);
            }
            //} else if(!selectedProcess) {
        } else if (tmpProcesses.length > 0 && !selectedProcess) {
            let foundDefault = tmpProcesses.find((p) => { return p.defaultSelected });
            if (foundDefault) {
                setSelectedProcess(foundDefault);
            } else {
                setSelectedProcess(tmpProcesses[0]);
            }
        }


    }, [rasci.processes, searchText, selectedPDCA, selected, selectedTask])


    useEffect(() => {

        if (selectedProcess) {
            setTimeout(() => {

                if (document.getElementById(selectedProcess._id)) {

                    document.getElementById("listholder").scrollTo({ left: 0, top: (document.getElementById(selectedProcess._id).offsetTop - 210), behavior: "smooth" })

                }
            }, 500);
        }

    }, [selectedProcess])



    const getOrganisation = (id) => {
        let org = rasci.organisations.find((o) => o._id === id);
        if (org) return org.name;
        return '';
    }
    /*
     {filteredProcesses.map((process, index) => {
                            return (
                                <div key={index} style={{ width: 'auto', padding: '8px', margin: '8px', backgroundColor: '#f7f7f7', border: '1px solid #f7f7f7', cursor: 'pointer', borderLeft: (selectedProcess._id == process._id) ? '2px solid #00006a' : '2px solid #f7f7f7' }} onClick={() => setSelectedProcess(process)}>
                                    {process.number}  {process.name}<br />
                                    <span style={{ fontSize: 'smaller' }}> <i>{getOrganisation(process.organisation)}</i></span>
                                </div>
                            )
                        })}
    */
    //   <TabsPDCA rasci={rasci} value={selectedPDCA} onChange={setselectedPDCA} />




    if (!rasci) return null;
    if (!rasci.processes) return null;
    if (!selectedProcess) return null;
    return (
        < div className='padding'>
            <Menu selected={2} fileID={fileID} history={history} />
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <Paper style={{ width: '33%', margin: '8px' }}>

                    <SearchBar value={searchText} onKeyUp={(text) => { setSearchText(text) }} onReset={() => { setSearchText(undefined) }} />
                    <div style={{ height: 'calc(100vh - 200px)', overflowY: 'auto' }} id="listholder">
                        {rasci.processes.filter(process => { return process.level == 0 }).map((chapter) => {
                            let items = [];
                            let found = false;
                            //hoofdstuk
                            items.push(<div key={chapter._id} style={{ width: 'auto', padding: '8px', backgroundColor: '#154372', color: 'white', border: '1px solid #f7f7f7' }} >  {chapter.number}  {chapter.name}   </div>)

                            rasci.processes.filter(process => { return process.level == 1 && process.parent == chapter._id }).map((paragraph) => {
                                if (filteredProcesses.filter(p => { return p.parent == paragraph._id }).length > 0) {
                                    items.push(<div key={paragraph._id} style={{ width: 'auto', padding: '8px', backgroundColor: '#3281d0', color: 'white', border: '1px solid #f7f7f7' }} >  {paragraph.number}  {paragraph.name}   </div>)

                                    filteredProcesses.filter(p => { return p.parent == paragraph._id }).map((process, index) => {
                                        found = true;

                                        items.push(
                                            <div id={process._id} key={process._id} style={{ width: 'auto', padding: '8px', margin: '8px', backgroundColor: '#f7f7f7', border: '1px solid #f7f7f7', cursor: 'pointer', borderLeft: (selectedProcess._id == process._id) ? '2px solid #00006a' : '2px solid #f7f7f7' }} onClick={() => { setSelectedProcess(process); setSelectedTask(null); setSelectedView(0) }} >
                                                {process.number}  {process.name}<br />
                                                <span style={{ fontSize: 'smaller' }}> <i>{getOrganisation(process.organisation)}</i></span>
                                            </div>
                                        )
                                    })
                                }

                            });
                            if (!found) return null;
                            return items;

                        })}

                    </div>
                </Paper>
                <Paper style={{ width: '67%', margin: '8px', padding: '8px' }}>
                    <Typography component="h2" >{selectedProcess.number} {selectedProcess.name}</Typography><br />
                    <Tabs value={selectedView} onChange={setSelectedView}>
                        <div >
                            ALGEMEEN
                        </div>
                        <div>
                            TAKEN
                        </div>
                        {selectedTask && <div>{selectedTask.number.toUpperCase()}</div>}
                    </Tabs>
                    {selectedView === 0 && <ViewProcessGeneral process={selectedProcess} rasci={rasci} />}
                    {selectedView === 1 && <ViewTasksProcesses process={selectedProcess} rasci={rasci} history={history} fileID={fileID} onSelect={(task) => { setSelectedTask(task); setSelectedView(2) }} />}
                    {selectedView === 2 && <div >
                        <div className='label padding'>Taak</div>
                        <ViewTaskGeneral task={selectedTask} rasci={rasci} fileID={fileID} history={history} />
                    </div>}
                </Paper>
            </div>
        </div>
    );
};

export default ViewProcess;