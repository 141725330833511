import React,{useEffect, useState} from 'react';
import { Paper } from '../../ui/paper';
import TabsProcessesShort from './tabs-processes-short';
import ServiceGeneral from '../../../services/general';
import ServiceRasci from '../../../services/rasci';
const ViewTasksProcesses = ({ funct, rasci ,onSelectProcess}) => {
    let arrRasci = ["R - Responsible", "A - Accountable", "S - Support", "C - Consult", "I - Informed"];
    const [selectedProcess, setSelectedProcess] = useState();
    const [filteredProcesses, setFilteredProcesses] = useState([]);

    useEffect(() => {
        let tmpProcesses = Object.assign([], rasci.processes);
     //   tmpProcesses = tmpProcesses.filter((process) => { return process.level === '2' });
     //   if (selectedProcess) {
    //        tmpProcesses = tmpProcesses.filter((process) => process._id === selectedProcess);
     //   }
    //    setFilteredProcesses(tmpProcesses);
     
    }, [rasci.processes])

  
/*
    const getPDCAColor = (process) => {
        if (process.pdca === 'P') return <span style={{ ...templatePCDA, backgroundColor: 'green' }}>P</span>
        if (process.pdca === 'D') return <span style={{ ...templatePCDA, backgroundColor: 'orange' }}>D</span>
        if (process.pdca === 'C') return <span style={{ ...templatePCDA, backgroundColor: '#ecec00' }}>C</span>
        if (process.pdca === 'A') return <span style={{ ...templatePCDA, backgroundColor: '#bb0000' }}>A</span>
        if (process.pdca === 'K') return <span style={{ ...templatePCDA, backgroundColor: 'black' }}>K</span>
        if (process.pdca === 'W') return <span style={{ ...templatePCDA, backgroundColor: 'blue' }}>W</span>
    }
      
    const getColorRasciItem = (rasci) => {
        const templatePCDA = { color: 'white', padding: '2px', borderRadius: '2px', margin: '4px', paddingLeft: '6px', paddingRight: '6px',  fontWeight: 'bold',textAlign:'center',width:'10px' ,display:'inline'};
    
        if (rasci === 'R') return <div style={{ ...templatePCDA, backgroundColor: '#c00000' }}>R</div>
        if (rasci === 'A') return <div style={{ ...templatePCDA, backgroundColor: '#0070c0' }}>A</div>
        if (rasci === 'S') return <div style={{ ...templatePCDA, backgroundColor: '#4da72e' }}>S</div>
        if (rasci === 'C') return <div style={{ ...templatePCDA, backgroundColor: '#ffbf00' }}>C</div>
        if (rasci === 'I') return <div style={{ ...templatePCDA, backgroundColor: 'black' }}>I</div> 
    }
          */
// <TabsProcessesShort rasci={rasci} value={selectedProcess} onChange={setSelectedProcess} />
    return (
        <div className='padding'>
           
            {arrRasci.map((rasciItem, index) => {
               // console.log(rasci.linkedActivities.filter((la) => la.functionID === funct._id && la.rasci === rasciItem[0]))

                let functIDs = rasci.linkedActivities.filter(la => { return la.functionID === funct._id && la.rasci === rasciItem[0]});
                let foundProcesses = rasci.processes.filter(t => { return functIDs.find(la => { return la.processID == t._id && t.level==2 }) });
                foundProcesses = ServiceGeneral.sortJSON(foundProcesses, 'number','123');
                return <Paper key={rasciItem} className='padding' style={{marginBottom:'6px'}}>
                    <b>{rasciItem}</b><br /><br />
                    {//rasci.linkedActivities.filter((la) => la.functionID === funct._id && la.rasci === rasciItem[0]).map((la, index) => {
                    foundProcesses.map((la, index) => {
                        let foundProcess =la;// rasci.processes.find((p) => p._id === la.processID);
                       
                        if (!foundProcess) return null;
                        return (
                            <div key={la._id} style={{paddingTop:'6px',cursor:'pointer'}} onClick={()=>{onSelectProcess(foundProcess._id)}}>{ServiceRasci.getColorRasciItem(rasciItem[0])} {foundProcess.number} {foundProcess.name}</div>
                        )
                    })}
                </Paper>
            })}
        </div>
    );
};

export default ViewTasksProcesses;