import React, { useEffect, useState } from 'react';

import ServiceRasci from '../../../services/rasci';
const ViewProcessTasks = ({ process, funct, rasci, history, fileID, onSelect }) => {
    const [linkedActivities, setLinkedActivities] = useState();
    const [foundTasks, setFoundTasks] = useState();
    useEffect(() => {
        if (process && rasci) {
            setLinkedActivities(rasci.linkedActivities.filter(ls => { return ls.processID === process._id }));


            let taskIDs = rasci.linkedActivities.filter(la => { return la.processID === process._id });
            let foundTasks = rasci.tasks.filter(t => { return taskIDs.find(la => { return la.taskID == t._id }) });
            setFoundTasks(foundTasks);

        }
    }, [process, rasci])


    const handleClickTask = (task) => {
        history && fileID && history.push(`/rasci/tasks/${fileID}/${task._id}`);
    }

    const getColorRasciItem = (process,task,funct)=>{
     if(!process || !task || !funct) return null;
       let found = linkedActivities.find(la => { return la.taskID === task._id && la.processID === process._id && la.functionID === funct._id});
      
       if(!found) return null;
        return   ServiceRasci.getColorRasciItem(found.rasci)

    }

    if (!process) return null;
    if (!foundTasks) return null;
    return (
        <div  >
            <table style={{width:'100%'}}>
              <tbody>
              {foundTasks.map((task, index) => {
                return (
                    <tr  key={index} >
                        <td  style={{width:'20px'}} > {getColorRasciItem(process,task,funct)}</td>
                    <td onClick={() => { if (onSelect) { onSelect(task); } else { handleClickTask(task) } }} style={{ width: 'auto', padding: '8px', margin: '8px', backgroundColor: '#f7f7f7', border: '1px solid #f7f7f7', cursor: 'pointer' }} >
                       {task.number} {task.name} 
                    </td>
                    </tr>)
            })}
                </tbody>
            </table>
          
        </div>
    );
};

export default ViewProcessTasks;