import React, { use, useEffect, useState } from 'react';
import { SearchBar } from '../../ui/searchBar';
import { Typography } from '../../ui/typography';
import { Tabs } from '../../ui/tabs';
import { Paper } from '../../ui/paper';
import ServiceGeneral from '../../../services/general';
import ViewRolesGeneral from './view-roles-general';
import ViewRolesProcesses from './view-roles-processes';
import ViewProcessGeneral from './view-process-general';
import TabsOrganisationsShort from './tabs-organisations-short';
import ViewProcessTasks from './view-process-tasks';
import Menu from './menu';
import ViewTaskGeneral from './view-tasks-general';
const ViewRoles = ({ rasci, selected,fileID ,history}) => {
    const [selectedFunction, setSelectedFunction] = useState();
    const [selectedView, setSelectedView] = useState(0);
    const [filteredFunctions, setfilteredFunctions] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedOrganisation, setSelectedOrganisation] = useState();
    const [selectedProcess, setSelectedProcess] = useState();
    const [selectedTask,setSelectedTask] = useState();

    useEffect(() => {
      
        if (selected && rasci.functions) {
            let funct = rasci.functions.find((p) => p._id === selected);
            
            if (funct) setSelectedFunction(funct);
        } else {
            if (rasci.functions && rasci.functions.length > 0) {
                setSelectedFunction(rasci.functions[0]);
            }
        }
        setfilteredFunctions(rasci.functions);
    }, [rasci.functions, selected])

    useEffect(() => {
        let tmpFunctions = Object.assign([], rasci.functions);
        if (selectedOrganisation) {
            tmpFunctions = tmpFunctions.filter((funct) => funct.organisation === selectedOrganisation);
        }
        if (searchText) {
            tmpFunctions = tmpFunctions.filter((funct) => JSON.stringify(funct).toLowerCase().includes(searchText.toLowerCase()));
        }
        tmpFunctions = ServiceGeneral.sortJSON(tmpFunctions, 'number', '123')
        setfilteredFunctions(tmpFunctions);
 
        tmpFunctions[0] && !selected && setSelectedFunction(tmpFunctions[0]);

    }, [searchText, selectedOrganisation])

    useEffect(() => {
        if (selectedView == 2) setSelectedView(0);
    }, [selectedFunction])


    const getOrganisation = (id) => {
        let org = rasci.organisations.find((o) => o._id === id);
        if (org) return org.name;
        return '';
    }

    const getProcess = (id) => {
        let process = rasci.processes.find((p) => p._id === id);
        return process;
    }

       useEffect(() => {

            if(selectedFunction){
                setTimeout(() => {  
                    if(document.getElementById(selectedFunction.number)){
                        document.getElementById("listholder").scrollTo(  {left:0,top:(document.getElementById(selectedFunction.number).offsetTop - 235), behavior: "smooth"})
            
                    }
                 }, 500);
            }
      
        }, [selectedFunction])


    if (!rasci) return null;
    if (!rasci.functions) return null;
    if (!selectedFunction) return null;

    return (
        < div className='padding'>
            <Menu selected={1} fileID={fileID} history={history}/>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <Paper style={{ width: '33%', margin: '8px' }}>
                    <TabsOrganisationsShort rasci={rasci} value={selectedOrganisation} onChange={setSelectedOrganisation} />
                    <SearchBar value={searchText} onKeyUp={setSearchText} onReset={() => { setSearchText(undefined) }} />
                        <div style={{height:'calc(100vh - 250px)',overflowY:'auto'}} id="listholder">
                            {rasci.organisations.map((organisation, index) => {
                                let found = false;
                                let items = [];
                                if(selectedOrganisation){
                                    if(selectedOrganisation==organisation._id){
                                        items.push(<div key={organisation._id} style={{ width: 'auto', padding: '8px', backgroundColor: '#154372',color:'white', border: '1px solid #f7f7f7' }} >  {organisation.number}  {organisation.name}   </div>)
                                
                                    }
                                }else{
                                    items.push(<div key={organisation._id} style={{ width: 'auto', padding: '8px', backgroundColor: '#154372',color:'white', border: '1px solid #f7f7f7' }} >  {organisation.number}  {organisation.name}   </div>)
                             
                                }
                                 {filteredFunctions.filter(ff=>{return ff.organisation==organisation._id}).map((funct, index) => {
                                    found = true;
                                    items.push(
                                        <div id={funct.number} key={funct._id} style={{ width: 'auto', padding: '8px', margin: '8px', backgroundColor: '#f7f7f7', border: '1px solid #f7f7f7', cursor: 'pointer', borderLeft: (selectedFunction.number == funct.number) ? '2px solid #00006a' : '2px solid #f7f7f7' }} onClick={() => {setSelectedView(0); setSelectedFunction(funct); setSelectedProcess(null) ;setSelectedTask(null);}}>
                                            {funct.number}  {funct.name}<br />
                                        </div>
                                    )
                                })}
                                if(!found) return null;
                                return items;
                            })}
                  
                    </div>
                </Paper>
                <Paper style={{ width: '67%', margin: '8px', padding: '8px' }}>
                    <Typography component="h2" >{selectedFunction.number} {selectedFunction.name}</Typography><br />
                    <Tabs value={selectedView} onChange={setSelectedView}>
                        <div >
                            TAAKOMSCHRIJVING
                        </div>
                        <div>
                            WERKPROCESSEN/PRODUCTEN
                        </div>
                        {selectedProcess && <div>{selectedProcess.name.toUpperCase()}</div>}
                        {selectedTask && <div>{selectedTask.number.toUpperCase()}</div>}
                    </Tabs>
                    {selectedView === 0 && <ViewRolesGeneral funct={selectedFunction} rasci={rasci} />}

                    {selectedView === 1 && <ViewRolesProcesses funct={selectedFunction} rasci={rasci} onSelectProcess={(id) => { setSelectedProcess(getProcess(id));setSelectedTask(null); setSelectedView(2) }} />}

                    {selectedView === 2 && <ViewProcessGeneral process={selectedProcess} rasci={rasci} />}

                    {selectedView === 2 && <div >
                        <div className='label padding'>Taken</div>
                        <ViewProcessTasks process={selectedProcess} funct={selectedFunction} rasci={rasci} history={history} fileID={fileID} onSelect={(task)=>{setSelectedTask(task);setSelectedView(3)}}/>
                    </div>}

                    {selectedView === 3 && <div >
                        <div className='label padding'>Taak</div>
                       <ViewTaskGeneral task={selectedTask} rasci={rasci} fileID={fileID}  history={history}/>
                        </div>}
                </Paper>
            </div>
        </div>
    );
};

export default ViewRoles;