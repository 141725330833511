
import React, { useContext, useEffect, useState } from 'react'
import FileService from '../services/file';
import StorageService from '../services/storage';
import { Context } from "../AppContext";
import { ContextUI } from '../uiContext'; 
const RasciTasksSearch = (props) => {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
 //   const [rasci, setRasci] = useState();
  //  const [selectedRole,setSelectedRole] = useState();

    useEffect(() => {
        contextUI.setTitle('Werkprocessen / producten');
        contextUI.setSubTitle('');
     //   contextUI.setShowBackButton(false);

       
            let foundFile = context.info.Index.find(file => {return file.Name == 'rasci.json'});

            if (foundFile) {
                FileService.getContent(foundFile.DPath, StorageService.getStorageFileName(foundFile), true)
                    .then(content => {
                        let obj = (typeof content === 'string') ? JSON.parse(content) : content;
                   
                        let foundTask = obj.tasks.find(role => role.id == props.match.params.number);
                        let foundLA;
                        if(foundTask){
                             foundLA = obj.linkedActivities.find(la=>{return la.taskID == foundTask._id});// {_id:'123'};
                        }

                        if(foundTask && foundLA){
                            props.history.push('/rasci/processes/' + foundFile.DPath + '/' + foundLA.processID + '/' + foundTask._id);                          
                        }else{
                            props.history.push('/');
                        }
                    })
                    .catch(err => {
                        console.log(err);
                       
                    })
            }
     
    }, [])
return null;
/*
    if (!rasci) return null;
    return (
        <div  >
            <ViewRoles rasci={rasci}  selected={selectedRole}  history={props.history} fileID={props.match.params.id}/>
        </div>
    )
        */
}
export default RasciTasksSearch
