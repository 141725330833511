import React from 'react';
import { Tabs } from '../../ui/tabs';

const Menu = ({selected,history,fileID}) => {
    const handleSelect = (index) => {
        switch(index){
            case 0:
                history.push('/');
            break;
            case 1:
                history.push('/rasci/roles/' + fileID)
            break;
            case 2:
                history.push('/rasci/processes/' + fileID)
            break;
            case 3:
                history.push('/rasci/table/' + fileID)
            break;
            case 4:
                history.push('/rasci/tasks/' + fileID)
            break;
        }
    }
    return (
        <Tabs value={selected} onChange={handleSelect}>
            <div>HOME</div>
            <div>ROLLEN/TAAKOMSCHRIJVINGEN</div>
            <div>WERKPROCESSEN/PRODUCTEN</div>
            <div>TABEL</div>
        </Tabs>
    );
};

export default Menu;