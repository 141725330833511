import React, { useContext} from 'react'
import { ContextUI } from '../../uiContext';
import { Menu as MenuIcon, MoreVert ,ArrowBack} from './coreIcons';
import { IconButton, Menu } from './core';
const Header = function Header() {
    const contextUI = useContext(ContextUI);
    const leftStyle ={};
    if(contextUI.shiftHeaderButton===true){
        leftStyle.paddingLeft = '80px'
    }   
    return (
        <div className='header padding-ios' style={{ backgroundColor: contextUI.primary.color, color: contextUI.primary.contrast }}>
            <table>
                <tbody>
                    <tr>
                        {contextUI.sideMenu && !contextUI.showBackButton && <td className="headerActionLeft" style={leftStyle}>
                            {contextUI.sideMenu && <IconButton onClick={() => { contextUI.setShowSideBar(true) }}><MenuIcon color='white' /></IconButton>}
                        </td>}
                        {contextUI.showBackButton &&<td className="headerActionLeft"  style={leftStyle}>
                            {contextUI.showBackButton && <IconButton onClick={() => window.history.back()} ><ArrowBack color='white' /></IconButton>}
                        </td>}
                    {contextUI.headerImage && <td className="headerActionLeft" style={{ textAlign: 'center' }}><img src={contextUI.headerImage} style={{position:'absolute',top:'12px'}}/></td>}
                        <td className="headerText" style={{ color: contextUI.primary.contrast,textAlign:contextUI.headerMenu?'left':'center' }}>
                            <span className='headerTitle'>{contextUI.title}</span><br />
                            <span className='headerSubTitle'>{contextUI.subTitle}</span>
                        </td>
                        <td className="headerActionRight" nowrap="nowrap">
                            {contextUI.headerActions}
                        </td >
                        {contextUI.headerMenu && <td className="headerMenu">
                            <Menu id="headermenu" noscroll={true} control={<IconButton><MoreVert color='white' /></IconButton>}>{contextUI.headerMenu}</Menu>
                        </td>}
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export { Header };
