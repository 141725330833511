import React, { useEffect, useState } from 'react';
import { Tooltip } from '../../ui/tooltip'; 
import ServiceGeneral from '../../../services/general';
import Menu from './menu';
const ViewTable = ({ rasci, history, fileID }) => {
const [selectedTask,setSelectedTask] = useState();
const [selectedFunction,setSelectedFunction] = useState();


  const getToolTipPDCA = (value) => {
    if (!value) return '';
    switch (value.toLowerCase()) {
      case 'w':
        return "W - Wet"
      case 'k':
        return "K - Kader"
      case 'p':
        return "P - Plan"
      case 'd':
        return "D - Do"
      case 'c':
        return "C - Check"
      case 'a':
        return "A - Act" 
    }
  }

  const viewItem = (item,id) => {
    history && fileID && history.push(`/rasci/${item}/${fileID}/${id}`); 
  }
  const viewTask = (processID,taskID) => {
    history && fileID && history.push(`/rasci/processes/${fileID}/${processID}/${taskID}`);
  }

  const getToolTipRASCI = (value) => {
    if (!value) return '';
    switch (value.toLowerCase()) {
      case 'r':
        return "R - Responsible"
      case 'a':
        return "A - Accountable"
      case 's':
        return "S - Support"
      case 'c':
        return "C - Consulted"
      case 'i':
        return "I - Informed" 
    }
  }

  if (!rasci) return null;
  return (
    < div className='padding'>
        <Menu selected={3} fileID={fileID} history={history}/>
      <table cellPadding={0} cellSpacing={0}   className='rascitable'>
        <thead>
        <tr>
            <th label="product" style={{ border: 'none' }}></th>
            <th label="pdca" style={{ border: 'none' }}></th>
            <th label="taken" style={{ border: 'none' }}></th>
            <th label="bron taak" style={{ border: 'none' }}></th>
            {rasci.organisations.map((org) => {
              let foundFunctions = rasci.functions.filter(f => { return f.organisation == org._id });
              if (foundFunctions.length == 0) return <td key={'1_org_'+org._id} className='rascitable-border-left rascitable-border-top'> </td>
              return foundFunctions.map((funct,i) => {
                //<div className='rascitable-rotate-text'>{funct.number} {funct.name}</div>
                return <th key={'2_functorg_'+funct._id + org._id} style={{ backgroundColor:(selectedFunction && selectedFunction._id == funct._id)?'#e0e0e0':'white' ,verticalAlign:'bottom', cursor:'pointer'}} className={ (i==0?'rascitable-border-first-organisation':'rascitable-border-left') +  ' rascitable-border-top'} onClick={()=>{viewItem('roles',funct._id)}}><img  src={ServiceGeneral.getTextVerticle(funct.number + ' ' + funct.name, 12)}/></th>
              })
            })}
          </tr> 
          <tr>
            <th label="product" style={{ border: 'none' }}></th>
            <th label="pdca" style={{ border: 'none' }}></th>
            <th label="taken" style={{ border: 'none' }}></th>
            <th label="bron taak" style={{ border: 'none' }}></th>
            {rasci.organisations.map((org) => {
              let foundFunctions = rasci.functions.filter(f => { return f.organisation == org._id });
              return <th key={'3_org_'+org._id} colSpan={foundFunctions.length} style={{textAlign:'center',zIndex:0,fontWeight:'normal'}} className={  'rascitable-border-first-organisation rascitable-border-top'}>{org.name}</th>
            })}
          </tr>
          </thead>
        <tbody>
          {rasci.processes.filter(pro => { return (pro.level == 0) }).map((chapter) => {
            let arrItems = [];
            //hoofdstuk
            arrItems.push(<tr key={chapter._id} style={{ backgroundColor: '#154372',color:'white' }}>
              <td  colSpan={4} >{chapter.number} {chapter.name}</td> 
              {rasci.organisations.map((org) => {
                let foundFunctions = rasci.functions.filter(f => { return f.organisation == org._id });
                return <td key={'4_chap_'+org._id} colSpan={foundFunctions.length}></td>
              })}

            </tr>)
 
            rasci.processes.filter(pro => { return pro.parent === chapter._id }).map(paragraph => {
              //paragraaf
              arrItems.push(<tr key={'5_para_'+paragraph._id} style={{ backgroundColor: '#3281d0',color:'white' }}>
                <td style={{ paddingLeft: '10px' }} colSpan={4}>{paragraph.number} {paragraph.name}</td>
              
                {rasci.organisations.map((org) => {
                  let foundFunctions = rasci.functions.filter(f => { return f.organisation == org._id });
                  return <td key={'6_para_'+org._id} colSpan={foundFunctions.length}> </td>
                })}
              </tr>) 
 
              rasci.processes.filter(pro => { return pro.parent === paragraph._id }).map(product => {
                //product
                let countProduct = 0;  //to show product only once 
                let taskIDs = rasci.linkedActivities.filter(la => { return la.processID === product._id });
                let foundTasks = rasci.tasks.filter(t => { return taskIDs.find(la => { return la.taskID == t._id }) });
              //  rasci.linkedActivities.filter(la => { return la.processID === product._id }).map(la => {
                foundTasks.map(task => {
                  //linked tasks
                  if (task) {
                  
                    arrItems.push(<tr key={'7_protask_'+  chapter._id + paragraph._id + product._id + task._id  } >
                      <td style={{ backgroundColor:(selectedTask && selectedTask._id == task._id)?'#e0e0e0':'white',paddingLeft:'20px',fontSize:'12px',cursor:'pointer'}} className={(countProduct == 0 &&'rascitable-border-top')+ ' rascitable-border-left'} onClick={()=>{viewItem('processes',product._id)}}>{countProduct == 0 && product.number + ' ' + product.name}</td>
                      <td style={{ backgroundColor:(selectedTask && selectedTask._id == task._id)?'#e0e0e0':'white',fontSize:'12px'}}  className={(countProduct == 0 &&'rascitable-border-top') + ' rascitable-border-left'}>{countProduct == 0 && <Tooltip key={"7_tooltip+"+chapter._id + paragraph._id + product._id + task._id } tooltip={getToolTipPDCA(product.pdca)}>{product.pdca}</Tooltip>}</td>
                      <td style={{ backgroundColor:(selectedTask && selectedTask._id == task._id)?'#e0e0e0':'white',fontSize:'12px',cursor:'pointer'}} className='rascitable-border-top rascitable-border-left' onClick={()=>{viewTask(product._id,task._id)}} >{task.number} {task.name}</td>
                      <td style={{ backgroundColor:(selectedTask && selectedTask._id == task._id)?'#e0e0e0':'white',fontSize:'12px'}} className='rascitable-border-top rascitable-border-left' >{task.reference}</td>
                      {rasci.organisations.map((org) => {
                        let foundFunctions = rasci.functions.filter(f => { return f.organisation == org._id });
                        if (foundFunctions.length == 0) return <td key={'8_protaskorg_'+product._id + task._id+org._id} className='rascitable-border-top rascitable-border-left'> </td>
                        return foundFunctions.map((funct) => {
                          let foundRASCI = rasci.linkedActivities.filter(la2 => { return la2.taskID == task._id && la2.processID == product._id && la2.functionID == funct._id })
                         
                          if (foundRASCI) {
                            return <td key={'9_rasc_'+task._id + funct._id + product._id} style={{ textAlign: 'center' ,cursor:'pointer',backgroundColor:((selectedFunction && selectedFunction._id == funct._id)||selectedTask && selectedTask._id == task._id)?'#e0e0e0':'white'}}  className='rascitable-border-top rascitable-border-left' onClick={()=>{setSelectedFunction(funct);setSelectedTask(task);}}>{foundRASCI.map((r,i) => { return <Tooltip key={"8_tooltip_"+chapter._id + paragraph._id + product._id + task._id + org._id + funct._id + r.rasci + i} tooltip={getToolTipRASCI(r.rasci)}>{r.rasci}</Tooltip> })}</td>
                          } else {
                            return <td key={'10_norasc_'+task._id + funct._id + product._id}  className='rascitable-border-top rascitable-border-left'> &nbsp;</td>
                          }
                        })
                      })}

                    </tr>)
                  }
                  countProduct++;
                })

              })
            })

            return arrItems;

          })}
        </tbody>
      </table>

    </div>
  );
};

export default ViewTable;