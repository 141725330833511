import React, {  useEffect, useState } from 'react';
import { SearchBar } from '../../ui/searchBar';
import { Typography } from '../../ui/typography';
import { Tabs } from '../../ui/tabs';
import { Paper } from '../../ui/paper';
import ServiceGeneral from '../../../services/general';
import ViewRolesGeneral from './view-roles-general'; 
import ViewTaskGeneral from './view-tasks-general';
import ViewTasksFunctions from './view-tasks-functions';
import { Button } from '../../ui/button';
import Menu from './menu';
const ViewTasks = ({ rasci ,selected,fileID ,history}) => {
    const [selectedTask, setSelectedTask] = useState();
    const [selectedView, setSelectedView] = useState(0);
    const [selectedFunction, setSelectedFunction] = useState();
    const [filteredTasks, setfilteredTasks] = useState([]);
    const [searchText, setSearchText] = useState(''); 
    const [selectedProcess, setSelectedProcess] = useState();
    
    useEffect(() => {
       if(selected && rasci.tasks){
        let task = rasci.tasks.find((p) => p._id === selected);
        if(task) setSelectedTask(task);
       }else{
        if (rasci.tasks && rasci.tasks.length > 0) {
            setSelectedTask(rasci.tasks[0]);
        }
        setfilteredTasks(rasci.tasks);
       }      
    }, [rasci.tasks,selected])

    useEffect(() => {
        let tmpTasks = Object.assign([], rasci.tasks);
     
        if (searchText) {
            tmpTasks = tmpTasks.filter((funct) => funct.name.toLowerCase().includes(searchText.toLowerCase()));
        }
        setfilteredTasks(ServiceGeneral.sortJSON(tmpTasks, 'number', '123'));
    }, [searchText])

    useEffect(() => {
       if(selectedView==2) setSelectedView(0);
    }, [selectedTask])


    useEffect(() => {
        if(selectedView==2) {
            setSelectedView(0);
        
        }
        setSelectedFunction(null);
     }, [selectedTask])
 
    const getFunction = (id) => {
        let funct = rasci.functions.find((p) => p._id === id);
       
        return funct;
    }

    useEffect(() => {
        if(selectedTask){
            setTimeout(() => {  
                if(document.getElementById(selectedTask.number)){
                    document.getElementById("listholder").scrollTo(  {left:0,top:(document.getElementById(selectedTask.number).offsetTop - 180), behavior: "smooth"})
        
                }
             }, 1000);
        }
  
    }, [selectedTask])

    const handleClickFunction = (id) => {
        history.push('/rasci/roles/' + fileID + '/' + id);
    }

    if (!rasci) return null;
    if (!rasci.functions) return null;
    if (!selectedTask) return null;
 
    return (
        < div className='padding'>
                    <Menu selected={4} fileID={fileID} history={history}/>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <Paper style={{ width: '33%', margin: '8px' }}>
                    <SearchBar value={searchText} onKeyUp={setSearchText} onReset={() => { setSearchText(undefined) }} />
                    <div id="listholder" style={{height:'calc(100vh - 200px)',overflowY:'auto'}}>
                    {rasci.processes.filter(process => { return process.level == 0 }).map((chapter) => {
                            let items = [];
                            //hoofdstuk
                            items.push(<div key={chapter._id} style={{ width: 'auto', padding: '8px', backgroundColor: '#154372',color:'white', border: '1px solid #f7f7f7' }} >  {chapter.number}  {chapter.name}   </div>)

                            rasci.processes.filter(process => { return process.level == 1 && process.parent == chapter._id }).map((paragraph) => {
                                items.push(<div key={paragraph._id} style={{ width: 'auto', padding: '8px', backgroundColor: '#3281d0',color:'white', border: '1px solid #f7f7f7' }} >  {paragraph.number}  {paragraph.name}   </div>)
                                let linkedTasks = [];
                                rasci.processes.filter(p=>{return p.parent == paragraph._id}).map((process, index) => {
                                    const linkedAct = rasci.linkedActivities.filter(la=>la.processID===process._id).map(la=>{return la.taskID});
                                    linkedTasks.push(...linkedAct);
                                });
                                     {filteredTasks.filter(ft=>{ return linkedTasks.indexOf(ft._id)>=0}).map((task, index) => {
                                        items.push(
                                            <div key={task._id + "_"+ paragraph._id} id={task.number} style={{ width: 'auto', padding: '8px', margin: '8px', backgroundColor: '#f7f7f7', border: '1px solid #f7f7f7', cursor: 'pointer', borderLeft: (selectedTask.number == task.number) ? '2px solid #00006a' : '2px solid #f7f7f7' }} onClick={() => { setSelectedTask(task); setSelectedProcess(null) }}>
                                                {task.number}  {task.name}<br />
                                            </div>
                                        )
                                    })}
                              
                              
                            });

                            return items;

                        })}
                 
                    </div>
                </Paper>
                <Paper style={{ width: '67%', margin: '8px', padding: '8px' }}>
                    <Typography component="h2" >{selectedTask.number}</Typography><br />
                    <Tabs value={selectedView} onChange={setSelectedView}>
                        <div >
                            TAAKOMSCHRIJVING
                        </div>
                        <div>
                            FUNCTIES
                        </div>
                        {selectedFunction && <div>{selectedFunction.name.toUpperCase()}</div>}
                    </Tabs>
                    {selectedView === 0 && <ViewTaskGeneral task={selectedTask} rasci={rasci} fileID={fileID}/>}

                    {selectedView === 1 && <ViewTasksFunctions task={selectedTask} rasci={rasci} onSelectedFunction={(id)=>{handleClickFunction(id);setSelectedFunction(getFunction(id));setSelectedView(2)}}/>}

                    {selectedView === 2 && selectedFunction && <Button onClick={()=>{  history && fileID && history.push(`/rasci/roles/${fileID}/${selectedFunction._id}`); }} style={{float:'right'}} variant="text">Bekijk Rollen / taakomschrijving </Button>}

                    {selectedView === 2 && selectedFunction && <div style={{clear:'both'}} ><ViewRolesGeneral funct={selectedFunction} rasci={rasci}  history={history}/></div>}


                </Paper>
            </div>
        </div>
    );
};

export default ViewTasks;