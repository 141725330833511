import React, { useEffect, useState } from 'react';
import TaskFunctions from './view-tasks-functions';
const ViewTaskGeneral = ({ task, rasci, fileID, history }) => {

    useEffect(() => {
        if (task && rasci) {

        }
    }, [task])

    const handleSelectFunction = (id) => {
        history && fileID && history.push(`/rasci/roles/${fileID}/${id}`);
    }

    if (!task) return null;
    return (
        <div className='padding'>
            {task.name} <br /><br />
            <div className='label'>Verwijzing</div>
            {task.reference} <br /><br />
            <div className='label'>Rollen</div>
            <TaskFunctions task={task} rasci={rasci} onSelectedFunction={(id) => { handleSelectFunction(id) }} />
        </div>
    );
};

export default ViewTaskGeneral;